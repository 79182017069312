import Swal from "sweetalert2/dist/sweetalert2.js";
import Cleave from "cleave.js";

const variables = {
  /**
   * @description Display modal alert with sweetaleart2
   * @param message: string
   * @param iconType: string
   * @param buttonText: string
   * @returns alert UI
   */
  modalAlert: (message, iconType: string, buttonText: string) => {
    Swal.fire({
      text: message,
      icon: iconType,
      buttonsStyling: false,
      confirmButtonText: buttonText,
      customClass: {
        confirmButton: "btn fw-bold btn-danger",
      },
    });
  },

  /**
   * @description Display toast alert with sweetaleart2
   * @param message: string
   * @param iconType: string
   * @param buttonText: string
   * @returns alert UI
   */
  toastAlert: (message, type: string) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 8000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    Toast.fire({
      icon: type,
      title: message,
    });
  },

  /**
   * @description Validate input field to accept only number
   *
   * @returns Null
   */
  acceptNumberOnly(event) {
    const keyCode = event.keyCode;
    if (keyCode < 48 || keyCode > 57) {
      event.target.value = event.target.value.replace(/[^\d]/g, "");
      event.preventDefault();
    }
  },

  /**
   * @description Add comma to number string
   * @param value: number
   *
   * @returns string
   */
  addCommaToNumber(event) {
    //Check if arrow keys are pressed - we want to allow navigation around textbox using arrow keys
    if (
      event.keyCode == 37 ||
      event.keyCode == 38 ||
      event.keyCode == 39 ||
      event.keyCode == 40
    ) {
      return;
    }

    // Remove letters from the input field
    event.target.value = event.target.value.replace(/[^\d]/g, "");

    // const cleanedValue = event.target.value;
    // return cleanedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    const ctrl = event.target;
    let val = ctrl.value;

    val = val.replace(/,/g, "");
    ctrl.value = "";
    val += "";
    const x = val.split(".");
    let x1 = x[0];
    const x2 = x.length > 1 ? "." + x[1] : "";

    const rgx = /(\d+)(\d{3})/;

    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "," + "$2");
    }

    setTimeout(() => {
      ctrl.value = x1 + x2;
    }, 100);
    return x1 + x2;
  },

  /**
   * @description Format numbers with commas and accept decimal point
   * @param value: number
   *
   * @returns string
   */
  numberFormatter(id) {
    return new Cleave(`#${id}`, {
      numeral: true,
      numeralThousandsGroupStyle: "thousand",
      numeralDecimalMark: ".",
    });
  },

  /**
   * @description Remove comma from number string
   * @param value: string
   *
   * @returns float
   */
  removeComma(value) {
    return parseFloat(value.replace(/,/g, ""));
  },

  /**
   * @description Calculate premium for each premium input field
   * @param rate: float
   * @param value: float
   * @param id: string
   *
   * @returns premium
   */
  premiumCalculator(rate, value, id) {
    const totalPremium = document.getElementById(id) as HTMLInputElement;
    // const premium = rate * value;
    const premium = value;

    if (isNaN(premium) == false) {
      totalPremium.textContent = premium
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },

  /**
   * @description Append each vehicle premium
   * @param rate: number
   * @param value: number
   *
   * @returns premium
   */
  vehiclePremium(rate: number, value: number) {
    let premium = 0 as number;
    // premium = rate * value;
    premium = value;
    return premium.toFixed(2);
  },

  /**
   * @description Calculate the total premium for each vehicle value
   *
   * @returns string
   */
  totalPremiumCalculator() {
    let totalPremium = 0;
    const elements = document.getElementsByClassName("before-premium");
    const grandPremium = document.getElementById(
      "total-premium"
    ) as HTMLInputElement;

    for (let i = 0, len = elements.length; i < len; i++) {
      totalPremium += this.removeComma(elements[i].textContent);
    }

    // Append total premium to widget
    grandPremium.textContent = totalPremium
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },

  /**
   * @description Calculate the total premium for each vehicle value
   *
   * @returns totalPremium
   */
  totalPremiumValue() {
    let totalPremium = 0;

    const grandPremium = document.getElementById(
      "total-premium"
    ) as HTMLInputElement;

    const elements = document.getElementsByClassName("before-premium");

    for (let i = 0, len = elements.length; i < len; i++) {
      totalPremium += this.removeComma(elements[i].textContent);
    }
    // Append total premium to widget
    grandPremium.textContent = totalPremium
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return parseFloat(totalPremium.toFixed(2));
  },

  /**
   * @description Calculate the total premium for each vehicle value
   *
   * @returns totalPremium
   */
  totalRawPremiumValue() {
    let totalPremium = 0;

    const elements = document.getElementsByClassName("before-premium");

    for (let i = 0, len = elements.length; i < len; i++) {
      totalPremium += this.removeComma(elements[i].textContent);
    }

    return parseFloat(totalPremium.toFixed(2));
  },

  /**
   * @description Calculate the total sum insured of each vehicle value
   *
   * @param value: string
   * @param id: number
   *
   * @returns totalSumInsured
   */
  totalSumInsured(value, id) {
    let totalSumInsured = 0;

    const rowSumInsured = document.getElementById(id) as HTMLInputElement;

    rowSumInsured.textContent = value;

    const elements = document.getElementsByClassName("sum-insured");

    const sumInsured = document.getElementById(
      "total-sum-insured-field"
    ) as HTMLInputElement;

    for (let i = 0, len = elements.length; i < len; i++) {
      totalSumInsured += this.removeComma(elements[i].textContent);
    }

    // Append total sum insured to hidden field
    sumInsured.value = totalSumInsured.toFixed(2);

    return totalSumInsured;
  },

  /**
   * @description Calculate the building claim estimate of repairs from the affected areas amount
   * @param position: number
   * @returns estimateOfRepairs: number
   */
  totalEstimateOfRepairs() {
    let estimateOfRepairs = 0;

    const elements = document.getElementsByClassName("affected-amount");

    for (let i = 0, len = elements.length; i < len; i++) {
      estimateOfRepairs += this.removeComma(elements[i].textContent);
    }

    return estimateOfRepairs;
  },

  /**
   * @description Resets grand premium text to zero
   *
   * @returns string 0.00
   */
  resetPremium() {
    const grandPremium = document.getElementById(
      "total-premium"
    ) as HTMLInputElement;

    grandPremium.textContent = "0.00";
  },

  /**
   * @description Print specific areas of a page
   * @param el: string
   *
   * @returns printed area
   */
  printPage(el) {
    const getFullContent = document.body.innerHTML;
    const printsection = document.getElementById(el) as HTMLElement;
    document.body.innerHTML = printsection.innerHTML;
    window.print();
    document.body.innerHTML = getFullContent;
    window.close();
  },

  /**
   * @description Convert file to base64 image string
   * @param fileObject: string
   *
   * @returns base64 string
   **/
  createBase64Image(fileObject) {
    const reader = new FileReader();
    reader.readAsDataURL(fileObject);

    reader.onload = function() {
      return reader.result as string;
    };

    reader.onerror = function(error) {
      console.log("Error: ", error);
    };
  },

  /**
   * @description Capitalize first letter of each word
   * @param value: string
   *
   * @returns capitalised word
   **/
  capitalize(value) {
    if (!value) return "";
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
  },

  /**
   * @description Capitalize each word
   * @param value: string
   *
   * @returns capitalised word
   **/
  capitalizeWord(value) {
    if (!value) return "";
    value = value.toString();
    return value.toUpperCase();
  },

  /**
   * @description Format date to human readble format
   * @param dateString: string
   *
   * @returns formmated date
   **/
  formatDate(dateString) {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    } as any;

    return new Date(Date.parse(dateString)).toLocaleDateString(
      undefined,
      options
    );
  },

  /**
   * @description Generate random unique password
   *
   * @returns string
   **/
  generatePassword() {
    const length = 12;
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let generatedPassword = "";

    for (let i = 0, n = charset.length; i < length; ++i) {
      generatedPassword += charset.charAt(Math.floor(Math.random() * n));
    }
    return generatedPassword;
  },

  /**
   * @description Add comma to a number
   * @param amount: number
   *
   * @returns string
   **/
  addCommaToNumberString(amount) {
    const initialAmount = parseFloat(amount);

    if (!isNaN(initialAmount) && initialAmount !== undefined) {
      return Intl.NumberFormat("en-US").format(initialAmount);
      // return initialAmount.toLocaleString("en-US");
      // return initialAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return "0.00";
    }
  },

  /**
   * @description Convert float to integer
   * @param floatvalue: float
   *
   * @returns integer value
   **/
  convertToInteger(floatvalue) {
    // `Math.trunc` was added in ECMAScript 6
    return Math.trunc(floatvalue);
  },

  /**
   * @description Get current date
   *
   * @returns current date
   **/
  currentDate() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();

    // return mm + "/" + dd + "/" + yyyy;
    // return mm + "-" + dd + "-" + yyyy;
    return yyyy + "-" + mm + "-" + dd;
  },

  /**
   * @description Get time
   * @returns current time
   **/
  currentTime() {
    const currentdate = new Date();
    const hours = String(currentdate.getHours()).padStart(2, "0");
    const minutes = String(currentdate.getMinutes()).padStart(2, "0");
    // const seconds = String(currentdate.getSeconds()).padStart(2, "0");

    // return hours + ":" + minutes + ":" + seconds;
    return hours + ":" + minutes;
  },

  /**
   * @description Get current date and time
   * @returns current datetime
   **/
  currentDateTime() {
    return this.currentDate() + "T" + this.currentTime();
  },

  /**
   * @description Add years date
   * @param startDate: string
   * @param numberOfYears: number
   *
   * @returns current date
   **/
  addYearToDate(startDate, numberOfYears) {
    const today = new Date(startDate);
    today.setFullYear(today.getFullYear() + numberOfYears);
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  },

  dateFormatter(date) {
    const d = new Date(date),
      year = d.getFullYear();
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  },

  /**
   * @description Add class to target ID
   * @param id: number
   * @param className: string
   *
   * @returns class name
   **/
  addClass(id, className) {
    console.log("Add class: " + id, className);
    const addClass = document.getElementById(id) as HTMLInputElement;
    addClass.classList.add(className);
  },

  /**
   * @description Remove class from taget ID
   * @param id: number
   * @param className: string
   *
   * @returns null
   **/
  removeClass(id, className) {
    console.log("Remove class: " + id, className);
    const removeClass = document.getElementById(id) as HTMLInputElement;
    removeClass.classList.remove(className);
  },

  /**
   * @description Remove underscore from a string with regex
   * @param string: string
   *
   * @returns null
   **/
  removeUnderscore(string) {
    return string.replace(/_/g, " ");
  },

  /**
   * @description Check if String contains special characters in input field
   * @param str: string
   *
   * @returns bool: true|false
   **/
  containsSpecialChars(str) {
    // const specialChars = `\`!@#$%^&*()_+\\-=\\[\\]{};':"\\|,.<>\\/?~`;
    const specialChars = `\`!@#$%^&*()_+\\=\\[\\]{};':"\\|,.<>\\/?~`;

    const result = specialChars.split("").some((specialChar) => {
      if (str.includes(specialChar)) {
        return true;
      }

      return false;
    });

    return result;
  },

  /**
   * Function to sort alphabetically an array of objects by some specific key.
   *
   * @param {String} property Key of the object to sort.
   */
  dynamicSort(property) {
    let sortOrder = 1;

    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function(a, b) {
      if (sortOrder == -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    };
  },

  /**
   * Function to truncate a string to a specified length.
   *
   * @param string: string
   * @param clip: string
   */
  truncate(string, clip) {
    return string.length > clip ? string.slice(0, clip - 1) + "..." : string;
  },

  scriptContainsClassName(className) {
    const script = document.getElementById(className) as HTMLInputElement;

    return script == null ? false : true;
    // return script.classList.contains(className);
  },

  /**
   * Function to calculate the months difference between two dates.
   *
   * @param startDate: string
   * @param endDate: string
   */
  monthsCalculator(startDate, endDate) {
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);

    const monthDiff = date1.getMonth() - date2.getMonth();
    const yearDiff = date1.getFullYear() - date2.getFullYear();
    return monthDiff + yearDiff * 12;
  },

  /**
   * Generate and return a unique random number
   */
  generateRandomNumber() {
    const timestamp = new Date().getTime(); // Get current timestamp
    const random = Math.random(); // Get a random decimal between 0 and 1
    const uniqueNumber = Math.floor(timestamp + random * 1000000); // Combine timestamp and random number

    return uniqueNumber;
  },

  isNonNullString(value) {
    return typeof value === "string" && value !== null && value !== undefined;
  },

  // HOMEPAGES
  ADMIN_DASHBOARD: "/admin/dashboard",
  BROKER_DASHBOARD: "/broker/clients",
  CLIENT_DASHBOARD: "dashboard",
  COOPERATIVE_DASHBOARD: "/cooperative/dashboard",
  FINANCE_DASHBOARD: "/finance/dashboard",

  //ROLES
  ADJUSTER_USER_ROLE: "adjuster",
  ADMIN_USER_ROLE: "admin-user",
  BROKER_USER_ROLE: "broker-user",
  COOPERATIVE_ADMIN_USER_ROLE: "cooperative-admin-user",
  FINANCE_USER_ROLE: "finance-user",
  INDIVIDUAL_CLIENT_USER_ROLE: "individual-client-user",
  SUPER_ADMIN_USER_ROLE: "super-admin",
  UNDERWRITER_USER_ROLE: "underwriter-user",

  //OTHERS
  EXPIRED_JWT_TOKEN: "Expired JWT Token",
  VUE_APP_GEOLOCATION_API_KEY: process.env.VUE_APP_GEOLOCATION_API_KEY,

  // Client and resuable API routes
  BASE_ROUTE: process.env.VUE_APP_BASE_URL,
  LOGIN_ROUTE: "/auth/login", //POST
  LOGOUT_ROUTE: "/auth/logout", //POST
  REGISTER_ROUTE: "/auth/register", //POST
  VERIFY_USER: "/auth/verify", //GET
  RESET_PASSWORD_TOKEN: "/auth/password/reset/initiate", //POST
  RESET_PASSWORD: "/auth/password/reset/complete", //POST
  VERIFY_EMAIL_ROUTE: "/verification", //GET
  RESEND_VERIFY_EMAIL_ROUTE: "/verification/resend", //POST
  INDIVIDUAL_CLIENT_DASHBOARD: "/dashboard", //GET
  PROFILE_OVERVIEW_INFORMATION: "/account/information", //GET
  PROFILE_PERSONAL_INFORMATION: "/account/information/personal", //GET & PATCH
  PROFILE_HOSPITAL_INFORMATION: "/account/information/hospital", //GET & PATCH
  PROFILE_EMPLOYER_INFORMATION: "/account/information/employer", //GET & PATCH
  PROFILE_RELATION_INFORMATION: "/account/information/relation", //POST & PATCH
  PROFILE_BENEFICIARY_INFORMATION: "/account/information/beneficiary", //GET & PATCH
  CHANGE_PASSWORD: "/account/password", //POST
  STATES_ROUTE: "/states", //GET
  STATE_LGAS_ROUTE: "/states", //POST
  LGAS_ROUTE: "/lga", //GET
  GENDER_ROUTE: "/gender", //GET
  RELATION_ROUTE: "/relation", //GET
  MARITAL_STATUS_ROUTE: "/marital-status", //GET
  BANK_ROUTE: "/bank", //GET
  RELATIONSHIP_TYPE_ROUTE: "/relationship", //GET
  PROFILE_AVATAR: window.localStorage.getItem("userAvatar"),
  MOTOR_RATE_ROUTE: "/rate",
  MOTOR_UNDERWRITERS_ROUTE: "/underwriters/package/MOT",
  PRIVATE_MOTOR_RATE_ROUTE: "/rate/MOT/PM",
  COMMERCIAL_MOTOR_RATE_ROUTE: "/rate/MOT/CM",
  HOUSE_UNDERWRITERS_ROUTE: "/underwriters/package/HOM",
  HOUSE_BUILDING_RATE_ROUTE: "/rate/HOM/HOU/BUI",
  HOUSE_PERSONAL_ACCIDENT_RATE_ROUTE: "/rate/HOM/HOU/HPA",

  // LEGACY ROUTES
  LEGACY: "/legacy",
  LEGACY_CLIENT: "/legacy/client",
  LEGACY_POLICY: "/legacy/policy",
  LEGACY_POLICY_UPLOAD: "/legacy/policy-upload",

  //Re-direct routes
  MOTOR_SHORT_CODE: "MOT",
  OWNED_BUILDING_SHORT_CODE: "HOU-BUI-OWN",
  CONTENTS_SHORT_CODE: "HOU-CON",

  AVATAR_ROUTE: `${process.env.VUE_APP_BASE_URL}/avatar/`,
  BUSINESS_CLASSES: "/business-class",
  CARS_ROUTE: "/cars",
  CART_ADDITION_ROUTE: "/cart/addition/", //GET & POST
  CART_ITEM_ROUTE: "/cart-item/", //GET
  CART_ROUTE: "/cart", //POST
  CART_RENEWAL_ROUTE: "/cart-renewal", // GET
  CERTIFICATE_ROUTE: "/certificate",
  CHECK_CART_PACKAGE_ROUTE: "/cart-item/exists", //GET
  CLAIM_IMAGES_ROUTE: `${process.env.VUE_APP_BASE_URL}/claim/`, //GET
  CLAIMS_ROUTE: "/claims", //GET & POST
  DOWNLOAD_MOTOR_SCHEDULE_TEMPLATE: "/schedule/cooperative/motor",
  HOME_INSURANCE_CART_ROUTE: "/householder/cart", //POST
  HOME_INSURANCE_PACKAGES_ROUTE: "/package/HOU", //POST
  INSURANCE_PACKAGES_ROUTE: "/package", //GET
  MEANS_OF_ID_IMAGE_ROUTE: `${process.env.VUE_APP_BASE_URL}/account/identification/`,
  NOTIFICATIONS_ROUTE: "/notifications", //GET
  PAYMENT_ROUTE: "/payment", //POST
  POLICY_IMAGES_ROUTE: `${process.env.VUE_APP_BASE_URL}/item/image/`, //GET
  POLICIES_ROUTE: "/policy", //GET
  PREMIUM_ROUTE: "/premium", //POST
  QUOTE_ROUTE: "/quote",
  RATES_ROUTE: "/rate",
  UNDERWRITER_LOGO_ROUTE: `${process.env.VUE_APP_BASE_URL}/logo/underwriter/`,
  UNDERWRITERS_ROUTE: "/underwriters",
  VERIFY_CONTACT_INFORMATION_ROUTE: "/account/information/contact/verify", //GET

  // BROKER ROUTES
  BROKER_CLIENT_POLICIES_ROUTE: "/broker/clients/policies", //GET
  BROKER_CLIENT_POLICY_DETAILS_ROUTE: "/broker/clients/policy", //GET
  BROKER_CLIENT_MOTOR_SCHEDULE_ROUTE: "/broker/clients/folio-item/", //GET
  BROKER_CLIENT_INVOICES_ROUTE: "/broker/clients/invoices", //GET
  BROKER_CLIENT_INVOICE_DETAILS_ROUTE: "/broker/clients/invoice/", //GET
  BROKER_CLIENTS_LIST_ROUTE: "/broker/clients", //GET
  BROKER_CLIENT_ROUTE: "/broker/client", //GET
  BROKER_CLIENTS_CLAIMS_ROUTE: "/broker/claims", //GET
  BROKER_COOPERATIVE_ROUTE: "/broker/cooperatives", //GET
  BROKER_BUSINESS_CLASS_ROUTE: "/broker/business-class", //GET
  BROKER_UMBRELLA_POLICY_ROUTE: "/broker/cooperative/policy", //POST && GET,
  BROKER_CREATE_COOPERATIVE_ROUTE: "/broker/cooperative", //GET
  BROKER_COOPERATIVE_RATE_ROUTE: "/broker/cooperative/rate", // GET
  BROKER_COOPERATIVE_DETAILS_ROUTE: "/broker/cooperative/details", //GET

  // COOPERATIVE ADMIN ROUTES
  COOPERATIVE_MEMBER_ROUTE: "/cooperative/member", //GET & POST
  COOPERATIVE_MEMBERS_ROUTE: "/cooperative/members", //GET & POST
  COOPERATIVE_MEMBERS_DETAILS_ROUTE: "/cooperative/member/", //GET
  COOPERATIVE_DASHBOARD_ROUTE: "/dashboard/coop-admin", //GET
  COOPERATIVE_POLICIES_ROUTE: "/cooperative/member-policies", //GET
  COOPERATIVE_POLICY_DETAILS_ROUTE: "/cooperative/member-policy/", //GET
  COOPERATIVE_UMBRELLA_POLICY_ROUTE: "/cooperative/policies", //POST && GET
  COOPERATIVE_CART_ROUTE: "/cooperative/cart", //POST
  COOPERATIVE_RATE_ROUTE: "/cooperative/rate", //POST && GET
  COOPERATIVE_UMRELLA_POLCIES_ROUTE: "/cooperative/cooperative-policies", //GET
  COOPERATIVE_CLAIMS_ROUTE: "/cooperative/claims", //POST && GET

  //FINANCE ROUTES
  FINANCE_DASHBOARD_ROUTE: "/dashboard/finance", //GET
  FINANCE_DEDIT_NOTES_ROUTE: "/finance/invoices", //GET
  FINANCE_CREDIT_NOTES_ROUTE: "/finance/credit-notes", //GET
  FINANCE_CREDIT_NOTE_DETAILS_ROUTE: "/finance/credit-note", //GET & POST
  FINANCE_PAYMENT_ROUTE: "/finance/payments", //GET
  FINANCE_PAYMENT_DETAILS_ROUTE: "/finance/payment", //GET
  FINANCE_CLIENTS_ROUTE: "/finance/clients", //GET
  FINANCE_CLIENTS_DETAILS_ROUTE: "/finance/client", //GET
  FINANCE_UNDERWRITERS_ROUTE: "/finance/underwriters", //GET
  FINANCE_UNDERWRITERS_DETAILS_ROUTE: "/finance/underwriter", //GET
  FINANCE_POLICY_DETAILS_ROUTE: "/finance/policy", //GET

  // SUPER ADMIN/ADMIN ROUTES
  SUPERADMIN_INVOICE_ROUTE: "/super-admin/invoices", //GET
  SUPERADMIN_CART_ITEM_ROUTE: "/super-admin/invoice", //GET
  SUPERADMIN_POLICY_ROUTE: "/super-admin/policies", //GET
  SUPERADMIN_POLICY_INFORMATION_ROUTE: "/super-admin/policy", //GET
  SUPERADMIN_COOPERATIVE_ROUTE: "/super-admin/cooperatives", //GET
  SUPERADMIN_CLIENTS_ROUTE: "/super-admin/clients", //GET
  SUPERADMIN_CLIENT_ROUTE: "/super-admin/client", //GET
  SUPERADMIN_BROKERS_ROUTE: "/super-admin/brokers", //GET
  SUPERADMIN_DEPARTMENTS_ROUTE: "/super-admin/departments", //GET
  SUPERADMIN_DEPARTMENT_ROUTE: "/super-admin/department", //GET
  SUPERADMIN_PAYMENTS_ROUTE: "/super-admin/payments", //GET
  SUPERADMIN_CLIENTS_INFORMATION_ROUTE: "/super-admin/client", //GET
  SUPERADMIN_INVOICES_ROUTE: "/super-admin/invoices", //GET
  SUPERADMIN_CREDIT_NOTES_ROUTE: "/super-admin/credit-notes", //GET
  SUPERADMIN_CLIENT_CLAIMS_ROUTE: "/super-admin/claims", //GET
  SUPERADMIN_SETTLE_CREDIT_NOTE_ROUTE: "/super-admin/credit-note/", //GET & POST
  SUPERADMIN_CLIENT_POLICIES_ROUTE: "/super-admin/policies", //GET
  SUPERADMIN_CLIENT_POLICY_DETAILS_ROUTE: "/super-admin/policy", //GET
  SUPERADMIN_CLIENTS_LIST_ROUTE: "/super-admin/clients", //GET
  SUPERADMIN_CLIENT_DETAILS_ROUTE: "/super-admin/client", //GET
  SUPERADMIN_CLIENT_MOTOR_SCHEDULE_ROUTE: "/super-admin/folio-item/", //GET
  SUPERADMIN_UNDERWRITERS_ROUTE: "/underwriters", //GET
  SUPERADMIN_BUSINESS_CLASS_ROUTE: "/super-admin/business-class", //GET
  SUPERADMIN_UMBRELLA_POLICY_ROUTE: "/cooperative/policy", //POST && GET
  SUPERADMIN_COOPERATIVE_RATE_ROUTE: "/super-admin/cooperative/rate", // GET
  SUPERADMIN_CREATE_COOPERATIVE_ROUTE: "/super-admin/cooperative", //POST
  SUPERADMIN_COOPERATIVE_DETAILS_ROUTE: "/super-admin/cooperative/details", //GET
  SUPERADMIN_PRODUCTS_ROUTE: "/super-admin/products", // GET
  SUPERADMIN_UNDERWRITER_ROUTE: "/super-admin/underwriter", // POST
  SUPERADMIN_UNDERWRITERS_LIST_ROUTE: "/super-admin/underwriters", // GET
  SUPERADMIN_UNDERWRITERS_RATE_ROUTE: "/super-admin/underwriter-rate", // GET
  SUPERADMIN_USER_ROUTE: "/super-admin/user", // POST && PATCH
  SUPERADMIN_USERS_ROUTE: "/super-admin/users", // GET
  SUPERADMIN_IBN_USERS_ROUTE: "/super-admin/ibn-users", // GET
  SUPERADMIN_ROLES_ROUTE: "/super-admin/roles", //GET
  SUPERADMIN_USER_STATUS_ROUTE: "/super-admin/activate-user", // POST
  SUPERADMIN_CREDIT_NOTE_DETAILS_ROUTE: "/super-admin/credit-note", //GET & POST
  SUPERADMIN_OVERVIEW: "/super-admin/overview", //GET

  //UNDERWRITERS ROUTES
  UNDERWRITERS_DASHBOARD_ROUTE: "/dashboard/underwriter", //GET
  UNDERWRITERS_CLIENTS_ROUTE: "/underwriter/clients", //GET
  UNDERWRITERS_CLIENTS_DETAILS_ROUTE: "/underwriter/client", //GET
  UNDERWRITERS_CREDIT_NOTES_ROUTE: "/underwriter/credit-notes", //GET
  UNDERWRITERS_POLICY_DETAILS_ROUTE: "/underwriter/policy", //GET
  UNDERWRITERS_CLIENT_CLAIMS_ROUTE: "/underwriter/claims", //GET
  UNDERWRITERS_POLICIES_ROUTE: "/underwriter/policies", //GET
  UNDERWRITERS_FOLIO_ROUTE: "/underwriter/folio-item",
  UNDERWRITERS_RATE_ROUTE: "/underwriters/rate",
};

export default variables;
